<template>
<div class="doc-container">
	<CRow>
        <!-- Disposition Content -->
        <CCol col="8">
			<CCard class="p-0">
                <!-- Card Header -->
				<CCardHeader class="d-flex">
                    <!-- Sender Profile Picture -->
					<div class="flex-grow-0">
						<div class="media-img-wrap">
							<div v-if="disposition.data.sender_profile_picture" class="avatar avatar-sm content-center">
								<img :src="'data:image/jpeg;base64,'+ disposition.data.sender_profile_picture" alt="user" class="avatar-img rounded-circle img-fluid">
							</div>
							<div v-else class="avatar avatar-sm">
								<img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
							</div>
						</div>
					</div>
                    <!-- Sender Information -->
					<div class="flex-grow-1 ml-10">
						<div class="d-flex flex-column">
							<div>
                                <b> {{ disposition.data.sender_name }} </b>
                                <span class="font-italic small">{{ disposition.data.sender_position }}</span>
                            </div>
							<div class="small">
                                kepada <b>{{ disposition.data.receiver_name }} </b>
                                <span class="font-italic">{{ disposition.data.receiver_position }}</span>
                            </div>
						</div>
					</div>
                    <!-- Timestamp -->
                    <div class="flex-grow-0 ml-10 text-right">
						<div class="flex flex-column">
                            <div class="small">{{ $moment(disposition.created_at).format("YYYY/MM/DD HH:mm") }}</div>
                        </div>
					</div>
				</CCardHeader>
                <!-- Card Body -->
				<CCardBody class="position-relative">
                    <!-- Attachment Preview -->
					<div class="doc-preview-container">
                        <!-- Loader -->
						<div v-if="disposition.loader == true" class="text-center p-3 mt-20">
							<font-awesome-icon size="2x" icon="spinner" spin class="mr-10 mb-20"/><br/>
							Memuat surat...
						</div>
                        <!-- Preview -->
						<div v-else>
							<div v-if="!disposition.base64 || disposition.base64 == ''" class="text-center p-3">
								<font-awesome-icon icon="info-circle" size="2x" class="text-info mb-10"/><br/>
								Tidak terdapat lampiran surat
							</div>
							<img v-else :src="disposition.base64" alt="Pratinjau Surat" class="img-fluid">
						</div>
					</div>
                    <!-- Disposition Information -->
					<div class="doc-info-container">
						<div class="doc-info-title">
							{{ disposition.data.content }}
						</div>
						<div class="doc-info-number">
							Instruksi : {{ disposition.data.command }}
						</div>
						<div class="doc-info-action">
							<CButton class="btn-sm" @click="showDispositionForm(disposition)">
								<font-awesome-icon icon="share" class="mr-1"/> Disposisi
							</CButton>
						</div>
					</div>
				</CCardBody>
			</CCard>
		</CCol>
        <!-- Diposition History Tree -->
        <CCol col="4">
			<CCard class="p-0">
				<CCardHeader>
					<h5 class="mb-0" style="font-size: 110%"><font-awesome-icon icon="history" class="mr-10"/> Riwayat</h5>
				</CCardHeader>
				<CCardBody class="doc-activity-container">
					<div v-for="(receiver, rIndex) in history.data" :key="rIndex" class="mt-1">
                        <div class="flex-grow-1 ml-10">
                            <div class="d-flex flex-column small">
                                <div>
                                    <b> Penemerima ke-{{ receiver.receiver_level + 1 }} </b>
                                </div>
                                <div>
                                    Dikirim pada
                                    <b>
                                        <vue-time-ago class="vue-fontawesome time-ago-notif" refresh :datetime="(receiver.created_at)" locale="id" :long="true"></vue-time-ago>
                                    </b>
                                </div>
                            </div>
                        </div>
                        <CListGroup>
                            <CListGroupItem href="#" v-for="(item, index) in receiver.users_list" :key="index" class="flex-column align-items-start">
                                <div class="d-flex w-100">
                                    <div class="flex-grow-0 mr-10">
                                        <div class="media-img-wrap">
                                            <div v-if="item.user_photo && item.user_photo != ''" class="avatar avatar-sm content-center">
                                                <img :src="'data:image/jpeg;base64,'+ item.user_photo" alt="user" class="avatar-img rounded-circle img-fluid">
                                            </div>
                                            <div v-else class="avatar avatar-sm">
                                                <img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
                                            </div>                 
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="small">
                                            <p class="mb-0">
                                                <b>{{item.name}} </b><span class="font-italic">{{ item.position }}</span>
                                            </p>
                                            <p>
                                                <span class="">{{ item.read ? ' telah membaca disposisi' : ' belum membuka disposisi' }}</span> 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </CListGroupItem>
                        </CListGroup>
                    </div>
				</CCardBody>
			</CCard>
		</CCol>
	</CRow>

	<!-- Disposition Popup Modal & Input Form -->
	<disposition-form
        v-bind:popup-modal-prop="pDispositionForm.popupModal"
        v-bind:edited-index-prop="pDispositionForm.editedIndex"
        v-bind:model-form-input-prop="pDispositionForm.input"
        v-bind:receiver-options-prop="pDispositionForm.receiverOptions"
        v-on:close-popup-modal="closeDispositionForm">
    </disposition-form>
</div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import {
    mapState
} from 'vuex'

const avatar = require('@/assets/img/avatar.svg')
import DispositionForm from '@/components/document/DispositionForm.vue'
export default {
	names: 'Detail',
	data () {
		const disposition = {
			loader: false,
            data: {},
            base64: null
		}
		const history = {
			loader: false,
			data: []
		}
		const pDispositionForm = {
			editedIndex: -1,
			receiverOptions: [],
			popupModal: {
				isShow: false,
				modalTitle: 'Disposisi Surat'
			},
			input: {
				// Memo
                content: null,
                docId: null,
                // Disposition
                memoId: null,
                receivers: [],
                selectedReceivers: null,
                commands: [],
                memoRecordId: null
			},
			defaultValue: {
				// Memo
                content: null,
                docId: null,
                // Disposition
                memoId: null,
                receivers: [],
                selectedReceivers: null,
                commands: [],
                memoRecordId: null
			}
		}

		return {
			avatar,
			disposition,
			history,
			pDispositionForm
		}
	},
	computed: {
		...mapState('auth', {
			fullname: 'fullname',
            profile_picture: 'profile_picture_thumb_base64'
		}),
        dispositionType() {
            return this.$route.params.type
        },
		dispositionId() {
			return this.$route.params.id
        },
        historyType() {
            let dispositionType = this.$route.params.type
            return (dispositionType == 'keluar') ? 'all' : 'direct'
        }
	},
	mounted () {
		this.loadDispositionContent()
		this.getHistoryList()
		this.getReceiverOptions()
	},
	methods: {
        async loadDispositionContent () {
            this.disposition.loader = true
            // Fetch Disposition
            let dispositionModel = await this.getDisposition()
                .catch((err) => {
                    this.disposition.loader = false
                    this.$toasted.error(err).goAway(3000)
                    return
                })
            this.disposition.data = dispositionModel
            // Update read status of disposition
            this.updateReadStatus()

            // Fetch attachment if disposition has document attached
            let documentId = dispositionModel.doc_id
            if (documentId && documentId != '') {
                let attachment = await this.getDocumentPreview()
                    .catch((err) => {
                        this.disposition.loader = false
                        // this.$toasted.error(err).goAway(3000)
                        return
                    })
                this.disposition.base64 = "data:image/jpeg;base64,"+
                    attachment.replace("b'","").replace("'","")
            }
            this.disposition.loader = false
        },
		getDisposition () {
			return new Promise((resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'disposition/fetch',
						formInput: {
							id: this.dispositionId
                        },
                        filter: {
                            type: this.dispositionType
                        }
					}
				).then( res => {
                    let item = res.result
					resolve(item)
				}).catch( err => {
                    reject('Gagal mengambil data disposisi, silahkan coba kembali!')
                })
			})
		},
		getDocumentPreview () {
			return new Promise((resolve, reject) => {
                this.$store.dispatch({
                    type: 'document/getAttachmentBase64',
                    formInput: {
                        fileId: this.disposition.data.doc_id,
                        outputType: 'thumbnail'
                    }
                }).then( res => {
                    let attachment = res.result.data
                    resolve(attachment)
                }).catch( err => {
                    reject(err)
                })
            })
		},
		getHistoryList () {
            this.$store.dispatch(
                {
                    type: 'disposition/getHistory',
                    formInput: {
                        dispositionId: this.dispositionId
                    },
                    filter: {
                        historyType: this.historyType
                    }
                }
            ).then( res => {
                let items = res.receiver_list
                this.history.data = items
            }).catch( err => {
                this.history.loader = false
                this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
            })
        },
        updateReadStatus () {
            this.$store.dispatch({
				type: 'disposition/update',
				formInput: {
                    dispositionId: this.dispositionId,
                    isRead: true
                }
			}).then( res => {
                if (res.error == null && res.result.is_read) {
                    console.log('Read status updated!')
                }
			})
        },
		// Disposition
        getReceiverOptions () {
			this.$store.dispatch(
				{
					type: 'user/getUser',
					pagination: {
						limit: 200,
						offset: 0
					},
					order: {
                        orderBy: 'fullname',
                        orderType: 'asc'
                    },
                    filter: {
                        isActive: 'true'
                    }
				}
			).then( res => {
				let items = []
				res.result.forEach((item) => {
                    let rowData = {
						label: item.fullname.trim(),
						userId: item.user_id,
                        fullname: item.fullname,
                        email: item.email,
                        rank: item.rank,
                        position: item.position,
                        command: null
                    }
					items.push(rowData)
				})
				
				this.pDispositionForm.receiverOptions = items
			})
        },
		showDispositionForm () {
            if (this.disposition.data && this.disposition.data != '') {
                this.pDispositionForm.modalTitle = 'Buat Disposisi'
                this.pDispositionForm.editedIndex = this.dispositionId
                this.pDispositionForm.input.memoId = this.disposition.data.memo_id
                this.pDispositionForm.input.content = this.disposition.data.content
                this.pDispositionForm.input.docId = this.disposition.data.doc_id
                this.pDispositionForm.input.memoRecordId = this.disposition.data.memo_rec_id
                this.pDispositionForm.popupModal.isShow = true
            }
		},
		closeDispositionForm () {
			this.pDispositionForm.popupModal.isShow = false
            this.pDispositionForm.input = Object.assign({}, this.pDispositionForm.defaultValue)
		}
	},
	components: {
        'disposition-form': DispositionForm
    }
}
</script>

<style scoped lang="scss">

</style>